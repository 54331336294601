import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const BranchList = Loadable(lazy(() => import('./BranchList')));
const BranchForm = Loadable(lazy(() => import('./BranchForm')));
const BranchDetails = Loadable(lazy(() => import('./BranchDetails')));

const branchRoutes = [
	{ path: '/branch', element: <BranchList />, auth: authRoles.admin },
	{ path: '/branch/new', element: <BranchForm />, auth: authRoles.admin },
	{ path: '/branch/:id', element: <BranchDetails />, auth: authRoles.admin },
	{ path: '/branch/update/:id', element: <BranchForm />, auth: authRoles.admin },
];

export default branchRoutes;