import { useRoutes } from 'react-router-dom';
import { SobaTheme } from './components';
import { SettingsProvider } from './contexts/SettingsContext';
import routes from './routes';

const App = () => {
	const content = useRoutes(routes);

	return (
		<SettingsProvider>
			<SobaTheme>{content}</SobaTheme>
		</SettingsProvider>
	);
};

export default App;
