import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const ParcelMovementIndex = Loadable(lazy(() => import('./ParcelMovementIndex')));
const DispatchToVehicle = Loadable(lazy(() => import('./DispatchToVehicle')));
const ReceiveFromVehicle = Loadable(lazy(() => import('./ReceiveFromVehicle')));
const DeliverToRecipient = Loadable(lazy(() => import('./DeliverToRecipient')));
const AssignedParcels = Loadable(lazy(() => import('./AssignedParcels')));
const TransferToCompany = Loadable(lazy(() => import('./TransferToCompany')));
const ReceiveFromCompany = Loadable(lazy(() => import('./ReceiveFromCompany')));
const ExternalCompany = Loadable(lazy(() => import('./ExternalCompany')));

const parcelMovementRoutes = [
	{ path: '/parcel-movement', element: <ParcelMovementIndex />, auth: authRoles.staff },
    { path: '/parcel-movement/dispatch-to-vehicle', element: <DispatchToVehicle />, auth: authRoles.staff },
    { path: '/parcel-movement/receive-from-vehicle', element: <ReceiveFromVehicle />, auth: authRoles.staff },
    { path: '/parcel-movement/deliver-to-recipient', element: <DeliverToRecipient />, auth: authRoles.staff },
    { path: '/parcel-movement/deliver-to-last-mile', element: <DeliverToRecipient />, auth: authRoles.staff },
    { path: '/parcel-movement/assigned-parcels', element: <AssignedParcels />, auth: authRoles.staff },
    { path: '/parcel-movement/transfer-to-company', element: <TransferToCompany />, auth: authRoles.staff },
    { path: '/parcel-movement/receive-from-company', element: <ReceiveFromCompany />, auth: authRoles.staff },
    { path: '/parcel-movement/external-company', element: <ExternalCompany />, auth: authRoles.staff },
];

export default parcelMovementRoutes;