import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const PasswordForm = Loadable(lazy(() => import('./PasswordForm')));

const userRoutes = [
	{ path: '/user/update-password', element: <PasswordForm />, auth: authRoles.staff },
];

export default userRoutes;