import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const CustomerList = Loadable(lazy(() => import('./CustomerList')));
const CustomerForm = Loadable(lazy(() => import('./CustomerForm')));
const CustomerDetails = Loadable(lazy(() => import('./CustomerDetails')));

const customerRoutes = [
	{ path: '/customer', element: <CustomerList />, auth: authRoles.staff },
	{ path: '/customer/new', element: <CustomerForm />, auth: authRoles.staff },
	{ path: '/customer/:id', element: <CustomerDetails />, auth: authRoles.staff },
	{ path: '/customer/update/:id', element: <CustomerForm />, auth: authRoles.staff },
];

export default customerRoutes;