import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "app/api/apiSlice";

const authenticatedPartner = JSON.parse(localStorage.getItem("authenticatedPartner"));

const initialState = {
    isAuthenticated: false,
    unauthorized: false,
    accessToken: authenticatedPartner?.accessToken ? authenticatedPartner.accessToken : null,
    role: authenticatedPartner?.role ? authenticatedPartner.role : null,
    email: authenticatedPartner?.email ? authenticatedPartner.email : null,
    name: authenticatedPartner?.name ? authenticatedPartner.name : null,
    refreshToken: authenticatedPartner?.refreshToken ? authenticatedPartner.refreshToken : null,
    branchID: authenticatedPartner?.branchID ? authenticatedPartner.branchID : null,
    companyID: authenticatedPartner?.companyID ? authenticatedPartner.companyID : null
}

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setPersist: (state, action) => {
            state.persist = action.payload;
        },
        setCredentials: (state, action) => {
            const { emailAddress, firstName, lastName, token: accessToken, userType: role, refreshToken, branchID, companyID, id: userID, staffID } = action.payload;
            const name = firstName + ' ' + lastName;
            localStorage.setItem("authenticatedPartner", JSON.stringify({ role, email: emailAddress, name, accessToken, refreshToken, branchID, companyID, userID, staffID }));
            state.role = role;
            state.email = emailAddress;
            state.name = name;
            state.branchID = branchID;
            state.companyID = companyID;
            state.accessToken = accessToken;
            state.refreshToken = refreshToken;
            state.isAuthenticated = true;
        },
        logout: (state, action) => {
            state.isAuthenticated = false;
            state.role = null;
            state.email = null;
            state.name = null;
            state.accessToken = null;
            localStorage.removeItem("authenticatedPartner");
        },
        unauthorized: (state) => {
            state.unauthorized = true;
        },
        resetUnauthorized: (state) => {
            state.unauthorized = false;
        }
    },
    extraReducers: (builder) => {}
});

export const {
    setPersist, setCredentials, logout, unauthorized, resetUnauthorized
} = authSlice.actions;

export default authSlice.reducer;