import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const ParcelList = Loadable(lazy(() => import('./ParcelList')));
const ParcelForm = Loadable(lazy(() => import('./ParcelForm')));
const ParcelDetails = Loadable(lazy(() => import('./ParcelDetails')));

const parcelRoutes = [
	{ path: '/parcel', element: <ParcelList />, auth: authRoles.staff },
	{ path: '/parcel/new', element: <ParcelForm />, auth: authRoles.staff },
	{ path: '/parcel/:id', element: <ParcelDetails />, auth: authRoles.staff },
	{ path: '/parcel/update/:id', element: <ParcelForm />, auth: authRoles.staff },
];

export default parcelRoutes;