import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const VehicleList = Loadable(lazy(() => import('./VehicleList')));
const VehicleForm = Loadable(lazy(() => import('./VehicleForm')));
const VehicleDetails = Loadable(lazy(() => import('./VehicleDetails')));

const vehicleRoutes = [
	{ path: '/vehicle', element: <VehicleList />, auth: authRoles.staff },
	{ path: '/vehicle/new', element: <VehicleForm />, auth: authRoles.staff },
	{ path: '/vehicle/:id', element: <VehicleDetails />, auth: authRoles.staff },
	{ path: '/vehicle/update/:id', element: <VehicleForm />, auth: authRoles.staff },
];

export default vehicleRoutes;