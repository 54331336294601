import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { logout, setCredentials, unauthorized } from '../features/auth/authSlice';
import { baseUrlDev, baseUrlPro } from './baseUrl';

const baseURL =
    window.location.hostname.includes("dev") || window.location.hostname.includes("localhost") || window.location.hostname.includes("test")
        ? baseUrlDev
        : baseUrlPro;

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
    baseUrl: baseURL,
    credentials: 'include',
    timeout: 15000,
    prepareHeaders: (headers, { getState }) => {
        headers.set('Accept', 'application/json');
        headers.set('Cache-Control', 'no-cache');
        headers.set('Pragma', 'no-cache');
        headers.set('Expires', '0');

        const authenticatedPartner = JSON.parse(localStorage.getItem("authenticatedPartner"));
        const token = getState().auth.token || authenticatedPartner?.accessToken;
        if (token) {
            headers.set("Authorization", `Bearer ${token}`)
        }
        return headers;
    }
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
    await mutex.waitForUnlock();
    let result = await baseQuery(args, api, extraOptions)

    if (result?.error?.originalStatus === 401) {
        if (!mutex.isLocked()) {
            const release = await mutex.acquire();

            try {
                console.log('sending refresh token');
                // send refresh token to get a new access token
                const authenticatedPartner = JSON.parse(localStorage.getItem("authenticatedPartner"));
                const refreshToken = api.getState().auth.refreshToken || authenticatedPartner.refreshToken;
                const refreshResult = await baseQuery({
                    url: 'UserAuthentications/RequestRefreshToken',
                    method: 'POST',
                    body: {
                        refreshToken
                    }
                }, api, extraOptions);

                if (refreshResult?.data) {
                    // store the new token
                    api.dispatch(setCredentials(refreshResult?.data));
                    // retry the original query with new access token
                    result = await baseQuery(args, api, extraOptions);
                    if (result?.error) {
                        api.dispatch(logout());
                    }
                } else {
                    // await baseQuery('/auth/logout', api, extraOptions);
                    api.dispatch(logout());
                }
            } finally {
                release();
            }
        } else {
            await mutex.waitForUnlock();
            result = await baseQuery(args, api, extraOptions);
        }
    } else if (result?.error?.originalStatus === 403) {
        api.dispatch(unauthorized())
    }
    return result;
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    tagTypes: [
        'Branch', 'Company', 'Customer', 'Driver', 'Parcel', 'ParcelTransaction',
        'ParcelType', 'Staff', 'Town', 'TransactionChannel', 'User', 'VehicleOwner',
        'Vehicle', 'VehicleType', 'CustomerRequest', 'Templating'
    ],
    refetchOnMountOrArgChange: 5,  //should be true or false
    refetchOnFocus: true,
    endpoints: builder => ({})
})