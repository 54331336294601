import { Navigate, useLocation, useParams } from 'react-router-dom';
import { flat } from 'app/utils/utils';
import AllPages from '../routes';
import { SobaLoading } from 'app/components';
import { useVerifyQuery } from 'app/features/auth/authApiSlice';
import { useSelector } from 'react-redux';

const getRoutePath = (pathname, params) => {

	if (!Object.keys(params).length) {
	  	return pathname; // we don't need to replace anything
	}

	let path = pathname;
	Object.entries(params).forEach(([paramName, paramValue]) => {
		if (paramValue) {
			path = path.replace(paramValue, `:${paramName}`);
		}
	});
	return path;
};

const userHasPermission = (pathname, user, routes) => {
	if (!user) {
		return false;
	}
	const matched = routes.find((r) => r.path === pathname);

	const authenticated = matched && matched.auth && matched.auth.length
		? matched.auth.includes(user.role)
		: false;
	return authenticated;
};

const AuthGuard = ({ children }) => {
	const params = useParams();
	const { pathname } = useLocation();

	const path = getRoutePath(pathname, params);

	const authenticatedPartner = JSON.parse(localStorage.getItem("authenticatedPartner"));

	const routes = flat(AllPages);

	const { isSuccess: isAuthenticated, isLoading } = useVerifyQuery();
	const { unauthorized } = useSelector(state => state.auth)

	// Load a spinner when the API is loading
	if (isLoading) {
		return <SobaLoading />
	}

	// Check if user is authenticated first
	if (!isAuthenticated) {
		return <Navigate replace to="/session/signin" state={{ from: pathname }} />;
	}

	// Check if user is unauthorized to access a page.
	if (unauthorized) {
		return <Navigate replace to="/session/unauthorized" state={{ from: pathname }} />
	}

	// If authenticated, check for permission
	const hasPermission = userHasPermission(path, authenticatedPartner, routes);

	return (
		<>
			{ hasPermission ? (
				children
			) : (
				<Navigate replace to="/session/unauthorized" state={{ from: pathname }} />
			)}
		</>
	);
};

export default AuthGuard;
