import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const CustomerRequestList = Loadable(lazy(() => import('./CustomerRequestList')));
const CustomerRequestReview = Loadable(lazy(() => import('./CustomerRequestReview')));
const CustomerRequestDetails = Loadable(lazy(() => import('./CustomerRequestDetails')));

const customerRequestRoutes = [
	{ path: '/customer-requests', element: <CustomerRequestList />, auth: authRoles.staff },
	{ path: '/customer-requests/:id', element: <CustomerRequestDetails />, auth: authRoles.staff },
	{ path: '/customer-requests/review/:id', element: <CustomerRequestReview />, auth: authRoles.staff },
];

export default customerRequestRoutes;