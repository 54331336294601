import { apiSlice } from '../../api/apiSlice';

const AUTH_URL = 'UserAuthentications/';

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        verify: builder.query({
            query: () => `${AUTH_URL}ValidateToken`,
            providesTags: (result, error, id) => [{ type: 'Dashboard', id }],
        }),
        login: builder.mutation({
            query: credentials => ({
                url: `${AUTH_URL}Login?UserType=Partner`,
                method: 'POST',
                body: { ...credentials, tokenHourlyDuration: 5 }
            })
        }),
        emailConfirmation: builder.mutation({
            query: ({ userID, confirmationCode }) => ({
                url: `${AUTH_URL}VerifyUserEmail`,
                method: 'POST',
                body: {
                    activationId: userID,
                    code: confirmationCode
                }
            })
        }),
        forgotPassword: builder.mutation({
            query: (email) => ({
                url: `${AUTH_URL}ForgetPassword`,
                method: 'POST',
                body: {
                    emailAddress: email
                }
            })
        }),
        resetPassword: builder.mutation({
            query: (resetData) => ({
                url: `${AUTH_URL}ResetPassword`,
                method: 'PUT',
                body: {
                    ...resetData
                }
            })
        })
    })
})

export const {
    useVerifyQuery,
    useLoginMutation,
    useEmailConfirmationMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation
} = authApiSlice;