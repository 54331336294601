import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const TemplatesList = Loadable(lazy(() => import('./TemplateList')));
const TemplatesForm = Loadable(lazy(() => import('./TemplateForm')));
const TemplatesDetails = Loadable(lazy(() => import('./TemplateDetails')));

const templatesRoutes = [
  { path: '/templates', element: <TemplatesList />, auth: authRoles.admin },
  { path: '/templates/:id', element: <TemplatesDetails />, auth: authRoles.admin },
  { path: '/templates/update/:id', element: <TemplatesForm />, auth: authRoles.admin },
];

export default templatesRoutes;